



































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

//import BaseField from "@/components/Shared/Table/BaseField.vue";
//import SitesServices from "@/services/SitesServices";

//import table from "@/utils/table";

@Component({
    components: {
        Modal: () => import("@/components/Shared/Modal.vue"),
        Editor: () => import("vue-image-markup/src/Editor.vue"),
    },
})
export default class ImageAnnotationModal extends Vue {
    /**
     * Defines if the modal is opened or not
     */
    @Prop({ default: false }) public show!: boolean;
    /**
     * Property set when the modal is opened
     */
    @Prop({ default: null }) public props!: any;

    private canvasWidth: number = 500;
    private canvasHeight: number = 250;
    public imageEditorRef: any;
    private showCanvas: boolean = false;
    //color and set size
    public currentColor: string = "black";
    public showColorMenu: boolean = false;
    public colors: string[] = ["black", "white", "blue", "red", "green"];
    public textSize: number = 16;
    public displayTextSize: number = 1;
    public strokeWidth: number = 4;
    public placeHolder: string = " ";
    public undoDisable: boolean = true;
    public eraserMode: boolean = false;
    public clickMode: string = "select";
    public clickEnableUndo: boolean = false;
    /**
     * #######
     * Methods
     * #######
     */

    private cancel() {
        this.imageEditorRef.clear();
        this.$emit("close");
        this.clickEnableUndo = false;
        this.selectmode();
    }

    private validate() {
        //let annotateImage = this.imageEditorRef.saveImage(); (ne pas utiliser car probl�me avec taille de l'image apr�s)
        let annotateImage = this.imageEditorRef.canvas.toDataURL({
            format: 'jpeg',
            quality: 0.92
        });
        this.$emit("validate", annotateImage, this.props.imageId);
        this.imageEditorRef.clear();
        this.clickEnableUndo = false;
        this.selectmode();
    }

    private setColor(color) {
        this.currentColor = color;
        this.showColorMenu = false;
        this.clickEnableUndo = false;
        this.draw();
    }

    private clickWillEnableUndo() { // va activer le bouton undo quand on fait une premi�re modification

        if (this.clickEnableUndo == true) {
            this.undoDisable = false;
        }
    }

    private undo() {
        this.imageEditorRef.undo();
        this.clickMode = "undo";
        this.checkUndoButton();           
    }

    private checkUndoButton() //Si la prochaine action undo enleve le background ou le change, on d�sactive le bouton.
    {

        let nextUndoBackground: string | undefined;
        let currentUndoBackground: string | undefined;

        if (this.imageEditorRef.history?.length > 1) {
            nextUndoBackground = this.imageEditorRef.history[this.imageEditorRef.history.length - 2].json.backgroundImage?.src;
            currentUndoBackground = this.imageEditorRef.history[this.imageEditorRef.history.length - 1].json.backgroundImage.src;
        }
      

        if ((nextUndoBackground === undefined) || (nextUndoBackground != currentUndoBackground) ){
            this.undoDisable = true;
        } else {
            this.undoDisable = false;
        }
        this.clickEnableUndo = false;
    }
            
    private erase() {
        this.clickEnableUndo = false;
        this.imageEditorRef.set("eraser");
        this.clickMode = "erase";
    }

    private selectmode() {
        // quand on select apres avoir erase, le select ne marche plus
        // mais quand on erase puis qu'on prend un autre outil, par exemple arrow, puis qu'on select, alors ca fonctionne 
        // donc maintenant, on se met en mode arrow juste avan de select pour plus que ca fasse le bug
        this.clickEnableUndo = false;
        this.imageEditorRef.set("arrow"); 
        this.imageEditorRef.set("selectMode");
        this.clickMode = "select";
    }

    private textSizeDown() {
        if (this.textSize <= 16) {
            this.textSize = 16;
            this.displayTextSize = this.textSize / 16;
        } else {
            this.textSize = this.textSize - 16;
            this.displayTextSize = this.textSize / 16;
        }
        this.clickEnableUndo = false;
        this.textZone();
    }
    private textZone() {
        //si jamais on appuis sur texte quand on vient de mettre un text ca bug, 
        //donc des qu'on appuie sur texte, on discard l'objet qu'on a actuellement
        this.imageEditorRef.canvas.discardActiveObject(); 
        this.selectmode();
        this.placeHolder = this.$t("bopModalSetup.ImageAnnotation.placeholder").toString();
        let textModeOptions = { fill: this.currentColor, fontFamily: 'Courier', fontSize: this.textSize, placeholder: this.placeHolder, fontWeight: 'bold' };
        this.imageEditorRef.set('text', textModeOptions);
        this.clickMode = "text";
        this.clickEnableUndo = true;
    }
    
    private textSizeUp() {
        if (this.textSize >= 160) {
            this.textSize = 160;
            this.displayTextSize = this.textSize / 16;
        } else {
            this.textSize = this.textSize + 16;
            this.displayTextSize = this.textSize / 16;
        }
        this.clickEnableUndo = false;
        this.textZone();
    }

    private strokeSizeUp() {
        this.strokeWidth = this.strokeWidth + 1;
        this.clickEnableUndo = false;
        this.draw();
    }
    private draw() {
        this.applyAction("freeDrawing")
        this.clickMode = "draw";
    }
    private circle() {
        this.applyAction("circle");
        this.clickMode = "circle";
    }
    private rectangle() {
        this.applyAction("rect");
        this.clickMode = "rect";
    }
    private arrow() {
        this.applyAction("arrow");
        this.clickMode = "arrow";
    }
    private strokeSizeDown() {
        if (this.strokeWidth <= 1) {
            this.strokeWidth = 1;
        } else {
            this.strokeWidth = this.strokeWidth - 1;
        }
        this.clickEnableUndo = false;
        this.draw();
    }


    private applyAction(type: string) {
        let actionModeParams = { stroke: this.currentColor, strokeWidth: this.strokeWidth }
        this.imageEditorRef.set(type, actionModeParams);
        this.clickEnableUndo = true;
    }


    /**
     * ######
     * Watchers
     * ######
     */
    @Watch("show")
    async onShowChange(value: boolean, oldValue: boolean) {

        if (value) {
            this.canvasHeight = this.props.imageHeight; // on prend les dimensions de la pi�ce jointe
            this.canvasWidth = this.props.imageWidth;
            this.showCanvas = true; //on affiche le canva 

            this.imageEditorRef = this.$refs.editor;
            this.imageEditorRef.canvas.setDimensions({ width: this.props.imageWidth, height: this.props.imageHeight }); // initialise les dimensions du canva a celle de la pi�ce jointe

            this.imageEditorRef.setBackgroundImage(this.props.contentBase64);//on met la pi�ce jointe en background de l'image
        }

    }
}
